

















































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { api as auditApi } from "@/store/modules/audit";
import {
  AuditProgress,
  AuditWorkflowStepInfo,
  PreparationStates,
  PreparationStateTransition,
} from "@/store/modules/audit/types";

import { VIcon, VFooter } from "vuetify/lib";
import ADocumentStateSnippet from "@/components/snippets/ADocumentStateSnippet.vue";
import { dialogRoute, DIALOG_NAMES, ROUTE_NAMES } from "@/routenames";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { AuditProgressDisplayType } from "@auditcloud/shared/lib/schemas";
import { stringifyExceptions } from "@auditcloud/shared/lib/utils/errors";
import { isDevEnv } from "@/initApp";

const auditModule = namespace(auditApi.namespace);

@Component({
  components: { VIcon, VFooter, ADocumentStateSnippet },
})
export default class AAuditFooter extends Vue {
  asyncWorkFor: null | "next" | "prev" = null;
  errorMsg: string | null = null;
  get errorSnackbar(): boolean {
    return this.errorMsg !== null;
  }
  set errorSnackbar(val: boolean) {
    if (val) {
      this.errorMsg = "";
    } else {
      this.errorMsg = null;
    }
  }
  @auditModule.Getter(auditApi.getters.getWorkflowSteps)
  workflowStepsAll!: AuditWorkflowStepInfo[];

  get workflowSteps() {
    return this.workflowStepsAll.filter(
      v => v.statusId !== AuditStatusId.Canceled
    );
  }

  @auditModule.Getter(auditApi.getters.getAuditId)
  auditId!: string;

  @auditModule.Getter(auditApi.getters.getAuditStatus)
  auditStatus!: string | null;

  @auditModule.Getter(auditApi.getters.hasPendingWrites)
  hasPendingWrites!: boolean;

  @auditModule.Getter(auditApi.getters.isCachedDocument)
  fromCache!: boolean;

  @auditModule.Getter(auditApi.getters.getAuditPermissions)
  auditPermissions!: AuditPermissions;

  @auditModule.Getter(auditApi.getters.getAuditProgressDisplayType)
  auditProgressDisplayType!: AuditProgressDisplayType;

  @auditModule.Getter(auditApi.getters.getAuditProgress)
  currentAuditProgress!: AuditProgress;

  @auditModule.Getter(auditApi.getters.getIsResultVisible)
  isResultVisible!: boolean;

  @auditModule.Getter(auditApi.getters.getPreparationStateTransitions)
  preparationStateTransitions!: {
    prev: PreparationStateTransition | null;
    next: PreparationStateTransition | null;
  };

  @auditModule.Action(auditApi.actions.switchToPreparationState)
  switchToPreparationState!: (payload: {
    newState: PreparationStates;
  }) => Promise<void>;

  async preparationTransitionHandler(
    type: "next" | "prev",
    newState: PreparationStates
  ): Promise<void> {
    try {
      console.log(`preparationStateTransitions${type}: started ...`);
      this.asyncWorkFor = type;
      this.errorMsg = null;

      await this.switchToPreparationState({ newState });
      console.log(`preparationStateTransitions${type}: ... done`);
    } catch (err) {
      console.log(`preparationStateTransitions${type}: ... failed.`, err);
      if (err instanceof Error && err.message.startsWith("api_errors")) {
        this.errorMsg = err.message;
      } else {
        this.errorMsg = stringifyExceptions(err, isDevEnv());
      }
    } finally {
      this.asyncWorkFor = null;
    }
  }

  preparationStateTransitionsPrev() {
    const prev = this.preparationStateTransitions.prev;

    if (prev) {
      this.preparationTransitionHandler("prev", prev.newState);
    }
  }

  preparationStateTransitionsNext() {
    const next = this.preparationStateTransitions.next;

    if (next) {
      this.preparationTransitionHandler("prev", next.newState);
    }
  }

  get auditProgressText() {
    const isSmallScreen = this.$vuetify.breakpoint.smAndDown;
    switch (this.auditProgressDisplayType) {
      case "percent": {
        const textId = isSmallScreen
          ? "views.audit.percentage_answered_findings_short"
          : "views.audit.percentage_answered_findings";
        return `${this.$t(textId, {
          percentage: this.currentAuditProgress.progress,
        })}`;
      }
      case "proportion": {
        const textId = isSmallScreen
          ? "views.audit.proportion_answered_findings_short"
          : "views.audit.proportion_answered_findings";
        return `${this.$t(textId, {
          answered_count: this.currentAuditProgress.audititem_answered_count,
          total_count: this.currentAuditProgress.audititem_count,
        })}`;
      }
      default:
        return "";
    }
  }

  get showAuditProgress() {
    const currentRouteName = this.$route.name;
    return (
      (currentRouteName === ROUTE_NAMES.AUDITEXECUTION ||
        currentRouteName === ROUTE_NAMES.AUDITWRAPUP) &&
      this.auditProgressDisplayType
    );
  }

  get hasStatusChangePermission() {
    return this.auditPermissions.changeStatus;
  }

  // TODO: Klasse/Verhalten kann über den Breakpoint abgefangen werden
  get displayClass(): string {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return "mobile";
    } else {
      return "standard";
    }
  }

  get workflowStep(): number {
    console.log("workflowStep:changed", this.auditStatus);
    const res = parseInt(this.auditStatus || "", 10);
    if (isNaN(res)) {
      return 0;
    } else {
      return res;
    }
  }

  get prev(): null | AuditWorkflowStepInfo {
    const res = this.workflowSteps[this.workflowStep - 1];
    if (res) {
      return res;
    } else {
      return null;
    }
  }

  get next(): null | AuditWorkflowStepInfo {
    const res = this.workflowSteps[this.workflowStep];
    console.log("Workstep next", res);
    if (res && this.workflowSteps[this.workflowStep + 1]) {
      return res;
    } else {
      return null;
    }
  }

  get auditIsInPlanning() {
    return (
      this.workflowSteps[this.workflowStep].statusId === AuditStatusId.Planning
    );
  }

  get translatedNextText() {
    if (this.next !== null) {
      return this.$t(this.next.transitionText);
    } else {
      return null;
    }
  }

  get hasRouteCorespondingToState() {
    return this.next?.route === this.$route.name ?? "error";
  }

  openTransitionPanel() {
    const newRoute = {
      name: this.$route.name ?? "error",
      params: {
        ...this.$route.params,
        dialogName: DIALOG_NAMES.NEXT,
      },
    };
    console.log("Trigger Transitionbar", newRoute);
    this.$router.push(newRoute);
  }

  openIntermediateResultDialog() {
    const params: any = this.$router.currentRoute.params;
    const routeName = this.$route.name ?? "error";
    const auditId = this.auditId;

    this.$router.push({
      name: dialogRoute(routeName, DIALOG_NAMES.INTERMEDIATE_RESULT_DIALOG),
      params: {
        auditId,
      },
    });
  }
}
