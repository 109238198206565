var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"flex-nowrap align-center pa-4 audit-footer",class:_vm.displayClass,staticStyle:{"overflow":"hidden","overflow-wrap":"break-word"},attrs:{"inset":"","app":"","height":"auto","color":"white"}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',[(_vm.showAuditProgress && _vm.isResultVisible)?_c('v-btn',{staticClass:"text-uppercase font-weight-bold primary--text",attrs:{"color":"info"},on:{"click":_vm.openIntermediateResultDialog}},[_vm._v(" "+_vm._s(_vm.auditProgressText)+" ")]):(
          _vm.hasRouteCorespondingToState &&
          _vm.hasStatusChangePermission &&
          _vm.preparationStateTransitions.prev
        )?_c('v-btn',{attrs:{"disabled":!_vm.preparationStateTransitions.prev.enabled || _vm.asyncWorkFor === 'next',"loading":_vm.asyncWorkFor === 'prev',"color":"primary"},on:{"click":_vm.preparationStateTransitionsPrev}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_c('div',{staticClass:"text-truncate text-truncate-safari-fix"},[_vm._v(" "+_vm._s(_vm.$ft(_vm.preparationStateTransitions.prev.name))+" ")])],1):_vm._e()],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"text-center"},[_c('ADocumentStateSnippet',{staticClass:"ml-2",attrs:{"small":false,"has-pending-writes":_vm.hasPendingWrites,"from-cache":_vm.fromCache}})],1):_vm._e(),_c('v-col',{staticClass:"text-right"},[(
          _vm.hasRouteCorespondingToState &&
          _vm.hasStatusChangePermission &&
          _vm.preparationStateTransitions.next
        )?_c('v-btn',{attrs:{"disabled":!_vm.preparationStateTransitions.next.enabled || _vm.asyncWorkFor === 'prev',"loading":_vm.asyncWorkFor === 'next',"color":"primary"},on:{"click":_vm.preparationStateTransitionsNext}},[_c('div',{staticClass:"text-truncate text-truncate-safari-fix"},[_vm._v(" "+_vm._s(_vm.$ft(_vm.preparationStateTransitions.next.name))+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1):(
          _vm.next && _vm.hasStatusChangePermission && _vm.hasRouteCorespondingToState
        )?_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.openTransitionPanel}},[_c('div',{staticClass:"text-truncate text-truncate-safari-fix"},[_vm._v(" "+_vm._s(_vm.translatedNextText)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1),_c('v-snackbar',{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.errorSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorMsg))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }